export interface UserAttributesResp {
  data: {
    get: {
      result: string
    }
  }
}

export interface UserAttributes extends Record<string, string | undefined> {
  language: string
  defaultSort: string
  defaultFilters: string
  display_partsTypeAll: string
  display_filtering: string
  display_userFiltering: string
  display_priceFld: string
  findit_partsProfessionalN: string
  phoneNumber: string
  findit_orderIfNotAvail: string
  EULA_accepted: string
  epeFeatures_buyersGuide: string
  epeFeatures_defaultState: string
  epeFeatures_jobs?: string
  epeFeatures_labor: string
  epeFeatures_plateToVin: string
  miscPreferences: string
  orgName: string
  userId: string
  mclOrgId: string
  orgId: string
  promo_enabled: string
  wdorgId: string
  searchPreferences: string
  searchPreferencesVersion: string
  searchPreferences_defaultSort: string
  dds: string
  cart_locPartMsg?: string
  cart_noteMaxSize: string
  cart_noteRequired: string
  cart_poMaxSize: string
  cart_pnMaxSize: string
  cart_poRequired: string
  cart_multiCart: string
  partDomain: string
  display_perCarQtyOne: string
  screenName: string
  courrierAcctId: string
  treePath: string
  shipmentStatusTrackerEnabled: string
  cart_nonPrimaryLocWarningEnabled: string
  cart_shipToOnCart: string
  orderAllCarts?: string
  rma?: string
  ads?: string
}

export enum UserAttributeKey {
  dds = 'dds',
  display_partsTypeAll = 'display_partsTypeAll',
  defaultFilters = 'defaultFilters',
  defaultSort = 'defaultSort',
  displaySortOrder = 'display_sortOrder',
  epeFeatures_buyersGuide = 'epeFeatures_buyersGuide',
  epeFeatures_defaultState = 'epeFeatures_defaultState',
  displayPlateSearch = 'epeFeatures_plateToVin',
  findit_partsProfessionalN = 'findit_partsProfessionalN',
  findit_orderIfNotAvail = 'findit_orderIfNotAvail',
  GFX_Enabled = 'epeFeatures_gfx',
  language = 'language',
  orgName = 'orgName',
  phoneNumber = 'phoneNumber',
  searchPreferences = 'searchPreferences',
  searchPreferencesVersion = 'searchPreferencesVersion',
  searchPreferencesDefaultSort = 'searchPreferences_defaultSort',
  userId = 'userId',
  partDomain = 'partDomain',
  cartNoteHasVehicle = 'cart_noteHasVehicle',
  epeFeatures_labor = 'epeFeatures_labor',
  courrierAcctId = 'courrierAcctId',
  treePath = 'treePath',
  shipmentStatusTrackerEnabled = 'shipmentStatusTrackerEnabled',
  cart_nonPrimaryLocWarningEnabled = 'cart_nonPrimaryLocWarningEnabled',
  displayShipToAddressInCartPage = 'cart_shipToOnCart',
  display_priceFld = 'display_priceFld',
  cart_locPartMsg = 'cart_locPartMsg',
  promo_enabled = 'promo_enabled',
  orderAllCarts = 'orderAllCarts',
  epeFeatures_jobs = 'epeFeatures_jobs',
  rma = 'rma',
  ads = 'ads',
  miscPreferences = 'miscPreferences',
}
